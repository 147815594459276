import { useState, useEffect, useMemo } from "react";
import './App.css';
import { fetchData } from './apiService';
import Table from './components/Table';

function App() {

  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      const apiResult = await fetchData();
      const originalData = Object.values(apiResult.data);
      const sortedData = originalData.sort((a, b) => (a.name > b.name) ? 1 : -1);
      setData(sortedData);
    })()
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Corona Daten",
        columns: [
          {
            Header: "Ort",
            accessor: "name"
          },
          {
            Header: "Landkreis",
            accessor: "county"
          },
          {
            Header: "Bundesland",
            accessor: "state"
          },
          {
            Header: "Fälle letzte 7 Tage/100.000 EW",
            accessor: "weekIncidence",
            Cell: ({ cell: { value } }) => {
              return (
                <>
                  {Math.round(value * 100) / 100}
                </>
              );
            }
          }
        ]
      }
    ], []);

  return (
    <div className="App">
      <Table columns={columns} data={data} />
    </div>
  );
}

export default App;
